exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-campaigns-caca-vazamentos-em-piscina-tsx": () => import("./../../../src/pages/campaigns/caca-vazamentos-em-piscina.tsx" /* webpackChunkName: "component---src-pages-campaigns-caca-vazamentos-em-piscina-tsx" */),
  "component---src-pages-construcao-e-projetos-tsx": () => import("./../../../src/pages/construcao-e-projetos.tsx" /* webpackChunkName: "component---src-pages-construcao-e-projetos-tsx" */),
  "component---src-pages-deteccao-de-vazamentos-tsx": () => import("./../../../src/pages/deteccao-de-vazamentos.tsx" /* webpackChunkName: "component---src-pages-deteccao-de-vazamentos-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-reforma-e-manutencao-tsx": () => import("./../../../src/pages/reforma-e-manutencao.tsx" /* webpackChunkName: "component---src-pages-reforma-e-manutencao-tsx" */),
  "component---src-pages-servicos-subaquaticos-tsx": () => import("./../../../src/pages/servicos-subaquaticos.tsx" /* webpackChunkName: "component---src-pages-servicos-subaquaticos-tsx" */)
}

